import { useState } from "react";
// @mui
import { Button, TableRow, TableCell } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { IInvoice } from "src/@types/invoice";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";
import { convertFixed } from "src/utils/formatNumber copy";
import Label from "src/components/label";
import { AuthService } from "src/services";

// ----------------------------------------------------------------------
type InvoiceType =
  | 'PACKAGE'
  | 'PROFIT_DAILY'
  | 'BRANCH_BONUS'
  | 'WITHDRAW'
  | 'DIRECT_COMMISSION'
  | 'GROWTH_COMMISSION'
  | 'INTEREST_ON_INTEREST'
  | 'BUY_TOKEN_OTC';

const typeLabels: Record<InvoiceType, string> = {
  PACKAGE: 'Buy Package',
  PROFIT_DAILY: 'Profit Daily',
  BRANCH_BONUS: 'Branch Bonus',
  WITHDRAW: 'Withdraw',
  DIRECT_COMMISSION: 'Direct Commission',
  GROWTH_COMMISSION: 'Growth Commission',
  INTEREST_ON_INTEREST: 'Reward on Commission',
  BUY_TOKEN_OTC: 'Buy WIC OTC',
};

export default function InvoiceTableRow({
  row,
  selected,
  onShowTransaction,
  filterStatus,
}: any) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleUpdateStatus = async () => {
    try {
      AuthService.transactionUpdateStatus({
        transaction_id: row.transaction_id,
      });
      toast.success("Update successfully");
      setTimeout(() => {
        onShowTransaction();
      }, 1500);
    } catch (error) {
      console.log(error);
      toast.error("Update failed");
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment.utc(row?.created_at).format("DD/MM/YYYY") +
            " - " +
            moment.utc(row?.created_at).format("HH:mm:ss")}
        </TableCell>
        <TableCell>
          {row?.tx_hash ? <a style={{color: '#000'}} target="_blank" href={`https://solscan.io/tx/${row?.tx_hash}`}>{shortenAddress(row?.tx_hash)}</a> : '--'}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.creator?.address ? (
            <CopyToClipboard
              text={row?.creator?.address}
              onCopy={() => toast.success("Coppied !")}
            >
              <div style={{ display: "flex" }}>
                <span style={{ flexShrink: "0" }}>
                  {shortenAddress(row?.creator?.address)}
                </span>
                <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
              </div>
            </CopyToClipboard>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <CopyToClipboard
            text={row?.user?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span style={{ flexShrink: "0" }}>
                {shortenAddress(row?.user?.address)}
              </span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {typeLabels[row?.type as InvoiceType] || ''}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.amount)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.token_value)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.description ? row?.description : '--'}
        </TableCell>

        {filterStatus === "PACKAGE" && (
          <TableCell
            align="left"
            style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
          >
            {row?.isSupport ? row?.isSupport : "--"}
          </TableCell>
        )}
        <TableCell
          align="left"
          style={{
            borderBottom: "1px dashed rgba(241, 243, 244, 1)",
            cursor: "pointer",
          }}
        >
          <Label
            style={{ cursor: "pointer" }}
            variant="soft"
            color={row?.status === 'SUCCESS' ? "success" : row?.status === 'FAILED' ? 'error' : 'warning'}
            onClick={() => {
              if (row?.status === "FAIL") {
                setOpenConfirm(true);
              }
            }}
          >
            {row?.status}
          </Label>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Update User"
        content={<>Are you want to update status of this transaction?</>}
        action={
          <Button
            variant="contained"
            color="success"
            onClick={handleUpdateStatus}
          >
            Update
          </Button>
        }
      />
    </>
  );
}
