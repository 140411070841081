import {
  Container,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import CardCommon from "src/components/cardCommon";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { useSettingsContext } from "src/components/settings";
import { TableHeadCustom, TableNoData, TablePaginationCustom, useTable } from "src/components/table";
import InvoiceTableRow from "./TableRow";
import { AuthService } from "src/services";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { ReportUserContainer } from "./styled";
import { InvoiceTableToolbar } from "src/sections/@dashboard/invoice/list";
import Label from "src/components/label";
import FilterSelect from "src/components/filterSelect";

const SERVICE_OPTIONS = ["all"];

const ReportUser = () => {
  const { themeStretch } = useSettingsContext();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
    onChangeDense,
    selected,
    onSort,
  } = useTable({ defaultOrderBy: "createDate" });
  const [tableData, setTableData] = useState<any>();
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<any>(null);
  const [filterStartDate, setFilterStartDate] = useState<any>(null);
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [dataReport, setDataReport] = useState<any>();

  //sort by status
  const handleFilterStatus = (
    // event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  //
  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // filter name
  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // api dashboard/transaction
  const onShowTransaction = async () => {
    try {
      const params = {
        page: page + 1,
        perPage: rowsPerPage,
        address: filterName,
        type: filterService || filterStatus,
        start_date: filterStartDate,
        end_date: filterEndDate,
      };
      const res: any = await AuthService.transaction(params);
      setTableData(res?.data);
      setCurrenPage(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (filterName) {
      onShowTransaction();
      getDataUserDetail();
    }
  }, [
    filterName,
    filterService,
    filterEndDate,
    page,
    filterStatus,
    rowsPerPage,
  ]);

  //service
  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
    if (event.target.value === "all") {
      setFilterService("");
    }
  };

  // table head
  const getTableHead = () => {
    let head = [
      { id: "date", label: "Create Date", align: "left" },
      { id: "hash", label: "Hash", align: "left" },
      { id: "addressCreator", label: "Address Creator", align: "left" },
      { id: "addressUser", label: "Address User", align: "left" },
      { id: "type", label: "Type", align: "left" },
      { id: "amoutUsd", label: "Amout (USDT)", align: "left", width: 140 },
      { id: "token", label: "Token Value", align: "left" },
      { id: "description", label: "Description", align: "left", width: 140 },
    ];
    if (filterStatus === "PACKAGE") {
      head.push({ id: "Support", label: "Support", align: "left" });
    }
    head.push({ id: "Status", label: "Status", align: "left", width: 140 });
    return head;
  };

  //tabs
  const SELECTDATA = [
    { value: "", label: "All", color: "info", count: currentPage?.total },
      {
        value: "PACKAGE",
        label: "Package",
        color: "warning",
      },
      {
        value: "PROFIT_DAILY",
        label: "Profit Daily",
        color: "error",
      },
      {
        value: "BRANCH_BONUS",
        label: "Branch Bonus",
        color: "default",
      },
      {
        value: "STAKING",
        label: "Staking",
        color: "default",
      },
      {
        value: "WITHDRAW",
        label: "Withdrawal",
        color: "default",
      },
      /* { value: 'CLAIM', label: 'Claim', color: 'default' },*/
      {
        value: "DIRECT_COMMISSION",
        label: "Direct Commission",
        color: "default",
      },
      {
        value: "GROWTH_COMMISSION",
        label: "Growth Commission",
        color: "default",
      },
  
      {
        value: "INTEREST_ON_INTEREST",
        label: "Investment Bonus",
        color: "default",
      },
      {
        value: "BUY_TOKEN_OTC",
        label: "Buy Token OTC",
        color: "default",
      },
  ];

  //get data user detail
  // DxeGfkVPSx1r65e2BzxQSKj2aAWbgvs186hxeQD5N38T
  const getDataUserDetail = async () => {
    try {
      const res: any = await AuthService.detailReportUser(filterName);
      setDataReport(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ReportUserContainer>
      <Helmet>
        <title> Report User | Wicchain</title>
      </Helmet>
      <CustomBreadcrumbs
        heading="Report User"
        links={[
          {
            name: "Report User",
          },
        ]}
      />
      <InvoiceTableToolbar
        filterName={filterName}
        filterService={filterService}
        filterEndDate={filterEndDate}
        onFilterName={handleFilterName}
        optionsService={SERVICE_OPTIONS}
        onResetFilter={handleResetFilter}
        filterStartDate={filterStartDate}
        onFilterService={handleFilterService}
        onFilterStartDate={(newValue) => {
          setFilterStartDate(newValue);
        }}
        onFilterEndDate={(newValue) => {
          setFilterEndDate(newValue);
        }}
        hiddenSearchDate={true}
        hiddenSelect={true}
      />
      <>
        <h2>Report User</h2>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Network Member"
                total={
                  dataReport?.left_team_number + dataReport?.right_team_number
                }
                image="/assets/images/card/icn_01.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Token Balance"
                total={dataReport?.wic_coin}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Commission"
                total={dataReport?.total_commission}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Invest ($)"
                total={dataReport?.total_invest_usd}
                image="/assets/images/card/icn_10.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Ranking"
                total={0}
                image="/assets/images/card/icn_14.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="USDT Balance"
                total={0}
                image="/assets/images/card/icn_16.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Profit Daily"
                total={dataReport?.total_profit_daily}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Invest ( Token )"
                total={dataReport?.total_invest_coin}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Package Name"
                name={dataReport?.package ? dataReport?.package : "--"}
                image="/assets/images/card/icn_13.png"
                chart={{
                  colors: ["#2859C5"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCEBF9"}
                color="#2859C5"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Withdrawal"
                percent={2.6}
                total={dataReport?.total_withdraw_coin}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
          </Grid>
        </Container>
        <h2>Transaction</h2>
        {/* <Tabs
          value={filterStatus}
          onChange={handleFilterStatus}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {TABS.map((tab: any) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <>
                  {tab?.count && (
                    <Label color={tab.color} sx={{ mr: 1 }}>
                      {tab.count}
                    </Label>
                  )}
                </>
              }
            />
          ))}
        </Tabs> */}
        <FilterSelect
          options={SELECTDATA}
          selectedValue={filterStatus}
          onChange={handleFilterStatus}
        />
        {/* <Divider /> */}

        <br />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={getTableHead()}
                rowCount={tableData?.length}
                numSelected={selected?.length}
                onSort={onSort}
              />

              <TableBody>
                {tableData && tableData?.length > 0 ? (
                  tableData?.map((row: any) => (
                    <InvoiceTableRow key={row?.transaction_id} row={row} />
                  ))
                ) : (
                  <TableNoData isNotFound={true} />
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
            count={currentPage?.total}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
      </>
    </ReportUserContainer>
  );
};

export default ReportUser;
