import React, { useEffect, useState } from "react";
import { PackageContainer } from "./styled";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import {
  BlockUpdateUser,
  FormSubmitButton,
  FormTitle,
} from "../Setting/FormUpdate/styled";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { PATH_DASHBOARD } from "src/routes/paths";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import axios from "axios";
import { convertFixed } from "src/utils/formatNumber";
import { AuthService } from "src/services";
import { C } from "@fullcalendar/core/internal-common";
import toast from "react-hot-toast";

interface FormValuesProps {
  commision: string;
  address: string;
  package: any;
  amount: number;
}

const Package = () => {
  const [getPrice, setGetPrice] = useState<any>();
  const [dataUsd, setDataUsd] = useState<number>(0);
  const [totalToken, setTotalToken] = useState<number>(0);

  const NewAddressSchema = Yup.object().shape({
    address: Yup.string(),
  });

  const defaultValues = {
    commision: "",
    address: "",
    package: 25,
    amount: 0,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const watchedPackage = watch("package");

  useEffect(() => {
    setDataUsd(Number(watchedPackage));
    if (getPrice > 0) {
      const calculatedTotalToken = convertFixed(
        (dataUsd / Number(getPrice)) * 1.005
      );
      setTotalToken(calculatedTotalToken);
      setValue("amount", Number(calculatedTotalToken));
    }
  }, [watchedPackage, getPrice, dataUsd, setValue]);

  //handle submit
  const onSubmit = async (data: any) => {
    try {
      const params = {
        amount: Number(data.package),
        address: data.address,
        isPayCommission: data.commision,
      };
      const res = await AuthService.buyPakage(params);
      if (res) {
        toast.success("Success");
      }
    } catch (error) {
      console.log(error);
      toast.error("Fail");
    }
  };

  // option select
  const optionsComissions = [
    {
      value: "1",
      label: "Paying commissions",
    },
    {
      value: "",
      label: "No Paying commissions",
    },
  ];

  //get price
  const handleGetWicPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.geckoterminal.com/api/v2/simple/networks/solana/token_price/3S2rq1PpUbHkLP1WrFbupiqo2U4cvk58BziaEpw7umQC"
      );
      if (res) {
        setGetPrice(
          res?.data?.data?.attributes?.token_prices[
            "3S2rq1PpUbHkLP1WrFbupiqo2U4cvk58BziaEpw7umQC"
          ]
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetWicPrice();
  }, []);

  // option select
  const optionsPackage = [
    {
      value: 25,
      label: "25 USDT",
    },
    {
      value: 100,
      label: "100 USDT",
    },
    {
      value: 1000,
      label: "1,000 USDT",
    },
    {
      value: 2000,
      label: "2,000 USDT",
    },
    {
      value: 3000,
      label: "3,000 USDT",
    },
    {
      value: 5000,
      label: "5,000 USDT",
    },
    {
      value: 10000,
      label: "10,000 USDT",
    },
  ];

  return (
    <>
      <Helmet>
        <title> Package | Wicchain</title>
      </Helmet>

      <CustomBreadcrumbs
        heading="Active Package"
        links={[
          {
            name: "Package",
          },
        ]}
      />

      <PackageContainer>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Wallet Address</span>
            </FormTitle>
            <RHFSelect native name="commision">
              {optionsComissions &&
                optionsComissions?.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </RHFSelect>
          </BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Email | Wallet | Username</span>
            </FormTitle>
            <RHFTextField
              name="address"
              label="Address"
              placeholder="Address Wallet"
            />
          </BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Package USDT</span>
            </FormTitle>
            {/* <RHFTextField name="package" label="Package" placeholder="1100" /> */}
            <RHFSelect native name="package">
              {optionsPackage &&
                optionsPackage?.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </RHFSelect>
          </BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Enter the amout of Token</span>
              <span>
                Token Price: ${getPrice > 0 && convertFixed(Number(getPrice))}
              </span>
            </FormTitle>
            <RHFTextField
              name="amount"
              label="Amout"
              value={totalToken}
              disabled
            />
          </BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormSubmitButton className="active" disabled={isSubmitting}>
              Submit
            </FormSubmitButton>
          </BlockUpdateUser>
        </FormProvider>
      </PackageContainer>
    </>
  );
};

export default Package;
