import { useState } from 'react';
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import { IInvoice } from 'src/@types/invoice';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shortenAddress } from 'src/components/shortAddress';
import toast from 'react-hot-toast';
import iconCopy from '../../../../assets/image-common/icn_copy.svg';
import Label from 'src/components/label';
import UserEditPage from '../../UserEditPage';
import FormUpdateUser from '../FormUpdate';

// ----------------------------------------------------------------------

type Props = {
  row: IInvoice;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function InvoiceTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  setOpen,
  setModalData
}: any) {
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  //modal update
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const handleOpenFormUpdate = (data: any) => {
    setOpenUpdate(true);
  };

  //modal delete
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleOpenFormDelete = (data: any) => {
    setOpenDelete(true);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left" style={{ borderBottom: '1px dashed rgba(241, 243, 244, 1)' }}>
          {moment(row?.created_at).format('DD/MM/YYYY') +
            ' - ' +
            moment(row?.created_at).format('HH:mm:ss')}
        </TableCell>
        <TableCell align="left" style={{ borderBottom: '1px dashed rgba(241, 243, 244, 1)' }}>
          <CopyToClipboard text={row?.setting_id} onCopy={() => toast.success('Coppied !')}>
            <div style={{ display: 'flex' }}>
              <span>{shortenAddress(row?.setting_id)}</span>
              <img style={{ cursor: 'pointer' }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell align="left" style={{ borderBottom: '1px dashed rgba(241, 243, 244, 1)' }}>{row?.name}</TableCell>
        <TableCell align="left" style={{ borderBottom: '1px dashed rgba(241, 243, 244, 1)' }}>{row?.value}</TableCell>
        <TableCell
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label
            variant="filled"
            color={"warning"}
            onClick={() => handleOpenFormUpdate(row)}
            style={{ cursor: "pointer" }}
          >
            Edit
          </Label>
        </TableCell>
        <TableCell
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label
            variant="filled"
            color={"error"}
            onClick={() => handleOpenFormDelete(row)}
            style={{ cursor: "pointer" }}
          >
            Delete
          </Label>
        </TableCell>
      </TableRow>

      {/* mobdal Update */}
      <ConfirmDialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        title="Update"
        content={
          <FormUpdateUser
            row={row}
            handleCloseUpdate={handleCloseUpdate}
            // onShowUser={onShow}
          />
        }
        disabledButton={true}
        action={
          false
        }
      />

      {/* mobdal Delete */}
      <ConfirmDialog
        open={openDelete}
        onClose={handleCloseDelete}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
