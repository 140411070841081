import ENDPOINT from "../Endpoint";
import axiosAuth from "../../utils/axiosAuth";

const AuthService = {
  login: (authBodyData: any) => axiosAuth.post(ENDPOINT.LOGIN, authBodyData),
  revenue: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.REVENUE}?filter[start_date]=${authBodyData.startDay}&filter[end_date]=${authBodyData.endDay}`
    ),
  transaction: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.TRANSACTION}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user][address]=${authBodyData.address}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  transactionUpdateStatus: (param: any) => {
    axiosAuth.patch(`${ENDPOINT.TRANSACTION}`, param);
  },
  withdraw: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.TRANSACTION}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=15&sort[order]=DESC&sort[field]=status&filter[type]=WITHDRAW
      ${authBodyData.type ? `&filter[status]=${authBodyData.type}` : ""}
        `
    ),
  withdrawStatus: (param: any) =>
    axiosAuth.patch(`${ENDPOINT.TRANSACTION}`, param),
  user: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.USER}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[op.or][0][address][op.like]=%${authBodyData.address}%&filter[op.or][1][address][op.like]=%${authBodyData.address}%`
          : ""
      }${authBodyData.isSupport && `&filter[is_support]=1`}${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),

  retportUser: (authBodyData: any) => axiosAuth.get(`${ENDPOINT.USER}/report`),

  userUpdate: (address: any, authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.USER}?address=${address}`, authBodyData),
  checkUserExist(param: any) {
    axiosAuth.get(`${ENDPOINT.USER}/${param}/check`);
  },
  withdrawal: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.WITHDRAWAL}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[field]=created_at&sort[order]=DESC${
        authBodyData.address
          ? `&filter[withdraw_address]=${authBodyData.address}`
          : ""
      }${authBodyData.status ? `&filter[status]=${authBodyData.status}` : ""}${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  approveWithdrawal: (id: any) =>
    axiosAuth.post(`${ENDPOINT.WITHDRAWALAPPROVE}/${id}/approve`),
  setting: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.SETTING}?pagination[page]=${authBodyData.page}&pagination[perPage]=${authBodyData.perPage}&sort[order]=DESC&sort[field]=created_at`
    ),
  createSetting: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.SETTING}`, authBodyData),
  createUpdate: (authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.SETTING}/${authBodyData.id}`, authBodyData),
  round: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.ROUND}?pagination[page]=${authBodyData.page}&pagination[perPage]=${authBodyData.perPage}&sort[order]=DESC&sort[field]=created_at`
    ),
  withdrawalApprove: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.ROUND}/${authBodyData}/approve`),
  withdrawalReject: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.ROUND}/${authBodyData}/reject`),
  investment: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.INVESTMENT}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.user
          ? `&filter[user_info][address]=${authBodyData.user}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  investmentList: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.INVESTMENTLIST}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user_info][op.or][0][address][op.like]=%${authBodyData.address}%&filter[user_info][op.or][1][address][op.like]=%${authBodyData.address}%`
          : ""
      }${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  investmentCreate: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.INVESTMENT}`, authBodyData),
  investmentUpdate: (id: any, authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.INVESTMENT}/${id}`, authBodyData),
  investmentAdd: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.INVESTMENTLIST}/manual`, authBodyData),
  level: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.LEVELS}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.user
          ? `&filter[creator_info][address]=${authBodyData.user}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  levelCreate: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.LEVELS}`, authBodyData),
  levelUpdate: (id: any, authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.LEVELS}/${id}`, authBodyData),
  affiliatesUp: (id: any, authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.AFFILIATES}/up/${id}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.user
          ? `&filter[creator_info][address]=${authBodyData.user}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  affiliatesDown: (id: any, authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.AFFILIATES}/down/${id}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.user
          ? `&filter[creator_info][address]=${authBodyData.user}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  affiliatesTree: (address: any) =>
    axiosAuth.get(
      `${ENDPOINT.AFFILIATES}/tree${address ? `?address=${address}` : ""}`
    ),
  logAction: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.LOG}/action?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user_info][op.or][0][address][op.like]=%${authBodyData.address}%&filter[user_info][op.or][1][address][op.like]=%${authBodyData.address}%`
          : ""
      }${
        authBodyData.action_type
          ? `&filter[op.or][0][action_type][op.like]=%${authBodyData.action_type}%&filter[op.or][1][action_type][op.like]=%${authBodyData.action_type}%`
          : ""
      }${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  logBalance: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.LOG}/balance?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user_info][op.or][0][address][op.like]=%${authBodyData.address}%&filter[user_info][op.or][1][address][op.like]=%${authBodyData.address}%`
          : ""
      }${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  // get user report
  detailReportUser: (address: string) =>
    axiosAuth.get(`${ENDPOINT.USER}/me?address=${address}`),
  //buy package
  buyPakage: (body: any) => axiosAuth.post(`/packages/buy`, body),
  //export data
  exportData: () => axiosAuth.get(`${ENDPOINT.EXPORTDATA}`),
  //data stacking
  getDataStaking: () => axiosAuth.get(`/stakings`),
};
export default AuthService;
