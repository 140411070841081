import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  CircularProgress,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// components
import Label from "../../../../components/label";
import Iconify from "../../../../components/iconify";
import Scrollbar from "../../../../components/scrollbar";
import CustomBreadcrumbs from "../../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../../components/settings";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../../../../components/table";
// sections
import { InvoiceTableToolbar } from "../../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";
import qs from "qs";
import { formatKeyFilter } from "src/utils/formatKeyFilter";
import InvoiceAnalytic from "src/sections/@dashboard/invoice/InvoiceAnalytic";
import { AffiliatesContainer } from "./styled";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = ["all"];

const TABLE_HEAD = [
  { id: "date", label: "Create Date", align: "left" },
  { id: "address", label: "Address", align: "left" },
  { id: "investToken", label: "Invest Token", align: "left" },
  { id: "investUSD", label: "Invest USD", align: "left" },
  { id: "ranking", label: "Ranking", align: "left", width: 140 },
  { id: "sponser", label: "Sponser", align: "left", width: 140 },
  { id: "level", label: "Level", align: "left" },
];

// ----------------------------------------------------------------------

export default function GeneralAffiliatesLeft({ data }: any) {
  const { themeStretch } = useSettingsContext();
  const theme = useTheme();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>();
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const getLengthByStatus = (status: string) =>
    tableData?.filter((item: any) => item?.action_type === status)?.length;

  const TABSLEFT = [
    { value: "", label: "All", color: "info", count: tableData?.left?.length },
  ] as const;
  const TABSRIGHT = [
    { value: "", label: "All", color: "info", count: tableData?.right?.length },
  ] as const;

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setFilterName(event.target.value);
    setLoading(true);
    try {
      const params = {
        page: page + 1,
        perPage: 15,
      };
      const res: any = await AuthService.affiliatesDown(
        event.target.value,
        params
      );
      setTableData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
    if (event.target.value === "all") {
      setFilterService("");
    }
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  return (
    <AffiliatesContainer>
      <Helmet>
        <title> Affiliates</title>
      </Helmet>

      <CustomBreadcrumbs
        heading="Affiliates"
        links={[
          {
            name: "Dashboard",
            href: PATH_DASHBOARD.root,
          },
          {
            name: "Affiliates",
          },
        ]}
      />
      <InvoiceTableToolbar
        filterName={filterName}
        filterService={filterService}
        filterEndDate={filterEndDate}
        onFilterName={handleFilterName}
        optionsService={SERVICE_OPTIONS}
        onResetFilter={handleResetFilter}
        filterStartDate={filterStartDate}
        onFilterService={handleFilterService}
        onFilterStartDate={(newValue) => {
          setFilterStartDate(newValue);
        }}
        onFilterEndDate={(newValue) => {
          setFilterEndDate(newValue);
        }}
        hiddenSearchDate={true}
        hiddenSelect={true}
      />
      {/* {tableData && ( */}
      {tableData && (
        <div>
          <div
            style={{
              textAlign: "center",
              border: "dotted 1px grey",
              padding: "10px",
            }}
          >
            <h3 style={{ marginTop: "0", marginBottom: "5px" }}>
              Search Sponser
            </h3>
            Address: <strong>{tableData?.sponser}</strong>
          </div>
        </div>
      )}
      <div className="wrapper-inner">
        <div className="content-left">
          <h2 style={{ textAlign: "center" }}>Affiliates Left</h2>
          <Container maxWidth={themeStretch ? false : "lg"}>
            {tableData && (
              <Card sx={{ mb: 5 }}>
                <Scrollbar>
                  <Stack
                    direction="row"
                    divider={
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderStyle: "dashed" }}
                      />
                    }
                    sx={{ py: 2 }}
                  >
                    <InvoiceAnalytic
                      title="Commission (Token)"
                      total={tableData?.left_team_commission?.token}
                      icon="ic:round-receipt"
                      color={theme.palette.success.main}
                    />

                    <InvoiceAnalytic
                      title="Commission (USD)"
                      total={tableData?.left_team_commission?.usd}
                      icon="eva:checkmark-circle-2-fill"
                      color={theme.palette.success.main}
                    />

                    <InvoiceAnalytic
                      title="Revenue (Token)"
                      total={tableData?.left_team_revenue?.token}
                      icon="eva:clock-fill"
                      color={theme.palette.warning.main}
                    />

                    <InvoiceAnalytic
                      title="Revenue (USD)"
                      total={tableData?.left_team_revenue?.usd}
                      icon="eva:bell-fill"
                      color={theme.palette.error.main}
                    />
                  </Stack>
                </Scrollbar>
              </Card>
            )}

            <Card>
              <Tabs
                value={filterStatus}
                onChange={handleFilterStatus}
                sx={{
                  px: 2,
                  bgcolor: "background.neutral",
                }}
              >
                {TABSLEFT.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label color={tab.color} sx={{ mr: 1 }}>
                        {tab.count}
                      </Label>
                    }
                  />
                ))}
              </Tabs>

              <TableContainer sx={{ position: "relative", overflow: "unset" }}>
                <TableSelectedAction
                  dense={dense}
                  numSelected={selected?.length}
                  rowCount={tableData?.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row: any) => row.id)
                    )
                  }
                  action={
                    <Stack direction="row">
                      <Tooltip title="Sent">
                        <IconButton color="primary">
                          <Iconify icon="ic:round-send" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Download">
                        <IconButton color="primary">
                          <Iconify icon="eva:download-outline" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Print">
                        <IconButton color="primary">
                          <Iconify icon="eva:printer-fill" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton color="primary">
                          <Iconify icon="eva:trash-2-outline" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />

                {/* <Scrollbar style={{ maxHeight: '580px', overflowY: 'scroll' }}> */}
                <Scrollbar>
                  <Table
                    size={dense ? "small" : "medium"}
                    sx={{ minWidth: 800 }}
                  >
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      numSelected={selected?.length}
                      onSort={onSort}
                    />

                    <TableBody
                      style={{
                        position: "relative",
                        height: loading ? "100px" : "",
                      }}
                    >
                      {!loading ? (
                        <>
                          {tableData?.left && tableData?.left?.length > 0 ? (
                            tableData?.left?.map((row: any) => (
                              <InvoiceTableRow
                                key={row?.user?.address}
                                row={row}
                              />
                            ))
                          ) : (
                            <TableNoData isNotFound={true} />
                          )}
                        </>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            </Card>
          </Container>
        </div>
        <div className="content-right">
          <h2 style={{ textAlign: "center" }}>Affiliates Right</h2>
          <Container maxWidth={themeStretch ? false : "lg"}>
            {tableData && (
              <Card sx={{ mb: 5 }}>
                <Scrollbar>
                  <Stack
                    direction="row"
                    divider={
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderStyle: "dashed" }}
                      />
                    }
                    sx={{ py: 2 }}
                  >
                    <InvoiceAnalytic
                      title="Commission (Token)"
                      total={tableData?.right_team_commission?.token}
                      icon="ic:round-receipt"
                      color={theme.palette.success.main}
                    />

                    <InvoiceAnalytic
                      title="Commission (USD)"
                      total={tableData?.right_team_commission?.usd}
                      icon="eva:checkmark-circle-2-fill"
                      color={theme.palette.success.main}
                    />

                    <InvoiceAnalytic
                      title="Revenue (Token)"
                      total={tableData?.right_team_revenue?.token}
                      icon="eva:clock-fill"
                      color={theme.palette.warning.main}
                    />

                    <InvoiceAnalytic
                      title="Revenue (USD)"
                      total={tableData?.right_team_revenue?.usd}
                      icon="eva:bell-fill"
                      color={theme.palette.error.main}
                    />
                  </Stack>
                </Scrollbar>
              </Card>
            )}

            <Card>
              <Tabs
                value={filterStatus}
                onChange={handleFilterStatus}
                sx={{
                  px: 2,
                  bgcolor: "background.neutral",
                }}
              >
                {TABSRIGHT.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label color={tab.color} sx={{ mr: 1 }}>
                        {tab.count}
                      </Label>
                    }
                  />
                ))}
              </Tabs>
              <Divider />

              <TableContainer sx={{ position: "relative", overflow: "unset" }}>
                <TableSelectedAction
                  dense={dense}
                  numSelected={selected?.length}
                  rowCount={tableData?.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row: any) => row.id)
                    )
                  }
                  action={
                    <Stack direction="row">
                      <Tooltip title="Sent">
                        <IconButton color="primary">
                          <Iconify icon="ic:round-send" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Download">
                        <IconButton color="primary">
                          <Iconify icon="eva:download-outline" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Print">
                        <IconButton color="primary">
                          <Iconify icon="eva:printer-fill" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton color="primary">
                          <Iconify icon="eva:trash-2-outline" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />

                {/* <Scrollbar style={{ maxHeight: '580px', overflowY: 'scroll' }}> */}
                <Scrollbar>
                  <Table
                    size={dense ? "small" : "medium"}
                    sx={{ minWidth: 800 }}
                  >
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      numSelected={selected?.length}
                      onSort={onSort}
                    />

                    <TableBody
                      style={{
                        position: "relative",
                        height: loading ? "100px" : "",
                      }}
                    >
                      {!loading ? (
                        <>
                          {tableData?.right && tableData?.right?.length > 0 ? (
                            tableData?.right?.map((row: any) => (
                              <InvoiceTableRow
                                key={row?.user?.address}
                                row={row}
                              />
                            ))
                          ) : (
                            <TableNoData isNotFound={true} />
                          )}
                        </>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            </Card>
          </Container>
        </div>
      </div>
      {/* )} */}
    </AffiliatesContainer>
  );
}
