import { useState } from "react";
// @mui
import { Button, TableRow, TableCell } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";
import Label from "src/components/label";
import FormUpdateUser from "./FormUpdateUser";

export default function InvoiceTableRow({ row, selected, onShowUser }: any) {
  const [openUpdateUser, setOpenUpdateUser] = useState(false);

  //modal update user
  const handleCloseUpdateUser = () => {
    setOpenUpdateUser(false);
  };
  const handleOpenFormUpdate = (data: any) => {
    setOpenUpdateUser(true);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment(row?.created_at).format("DD/MM/YYYY") +
            " - " +
            moment(row?.created_at).format("HH:mm:ss")}
        </TableCell>
        <TableCell
          align="left"
          style={{
            width: "120px",
            borderBottom: "1px dashed rgba(241, 243, 244, 1)",
          }}
        >
          <CopyToClipboard
            text={row?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span>{shortenAddress(row?.address)}</span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.user_investment?.invest_coin
            ? row?.user_investment?.invest_coin > 0 &&
              row?.user_investment?.invest_coin < 0.01
              ? row?.user_investment?.invest_coin
              : row?.user_investment?.invest_coin.toFixed(2)
            : "0"}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.user_investment?.invest_usd
            ? row?.user_investment?.invest_usd?.toFixed(2)
            : "0"}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.user_balance?.coin
            ? row?.user_balance?.coin > 0 && row?.user_balance?.coin < 0.01
              ? row?.user_balance?.coin
              : row?.user_balance?.coin.toFixed(2)
            : "0"}
        </TableCell>
        {/* <TableCell align="left" style={{ borderBottom: '1px dashed rgba(241, 243, 244, 1)' }}>{row?.user_balance?.money_auth_token?.toFixed(2)}</TableCell> */}
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.total_profit_daily
            ? row?.total_profit_daily > 0 && row?.total_profit_daily < 0.01
              ? row?.total_profit_daily
              : row?.total_profit_daily.toFixed(2)
            : "0"}
        </TableCell>

        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.total_revenue_usd ? row?.total_revenue_usd?.toFixed(2) : "--"}
        </TableCell>

        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.package_info ? row?.package_info?.name : "--"}
        </TableCell>
        <TableCell align="left" style={{ width: "120px" }}>
          <CopyToClipboard
            text={row?.sponser?.username}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span>{shortenAddress(row?.sponser?.username)}</span>
              <img
                width={22}
                style={{ cursor: "pointer" }}
                src={iconCopy}
                alt="Copy"
              />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label variant="soft" color={row?.is_support ? "success" : "error"}>{row?.is_support ? "True" : "False"}</Label>
        </TableCell>

        <TableCell
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label variant="soft" color={row?.is_lock ? "success" : "error"}>
            {row?.is_lock ? "True" : "False"}
          </Label>
        </TableCell>
        <TableCell
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label
            variant="soft"
            color={row?.is_swap ? "success" : "error"}
          >
            {row?.is_swap ? "True" : "False"}
          </Label>
        </TableCell>

        <TableCell
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label variant="soft" color={row?.is_transfer ? "success" : "error"}>
            {row?.is_transfer ? "True" : "False"}
          </Label>
        </TableCell>
        <TableCell
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label
            variant="filled"
            color={"warning"}
            onClick={() => handleOpenFormUpdate(row)}
            style={{ cursor: "pointer" }}
          >
            Update
          </Label>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openUpdateUser}
        onClose={handleCloseUpdateUser}
        title="Update User"
        content={
          <FormUpdateUser
            row={row}
            handleCloseUpdateUser={handleCloseUpdateUser}
            onShowUser={onShowUser}
          />
        }
        disabledButton={true}
        action={
          false
          // <Button variant="contained" color="error" onClick={handleUpdateUser}>
          //   Update
          // </Button>
        }
      />
    </>
  );
}
