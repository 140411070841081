import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '0';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export const formatNumberMega = (num?: number) => {
  if (num) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(num);
  } else {
    return "0.00";
  }
};

export const convertFixed = (value: any) => {
  if (Number(value) === 0)
    return value?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else if (Number(value) > 1e3)
    return value?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else if (Number(value) < 1 && Number(value) >= 9e-4)
    return value?.toFixed(4);
  else if (Number(value) < 9e-4 && Number(value) >= 9e-7)
    return value?.toFixed(7);
  else if (Number(value) < 9e-8 && Number(value) >= 9e-11)
    return value?.toFixed(11);
  else return value.toFixed(2);
};