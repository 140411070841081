import { useEffect, useState } from "react";
import { AuthService } from "src/services";
import { BinaryInner, ButtonShowFull } from "./styled";
import NodeItem from "./Node";
import Tree from "react-d3-tree";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  TextField,
} from "@mui/material";
import NodeModal from "./Node/Modal";
import toast from "react-hot-toast";
import zIndex from "@mui/material/styles/zIndex";
import { position } from "stylis";

const AffiliateTree = () => {
  const [loading, setLoading] = useState(true);
  const [treeData, setTreeData] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [targetSearch, setTargetSearch] = useState<any>();
  const [target, setTarget] = useState<any>();
  const [targetClicked, setTargetClicked] = useState(false);
  const [value, setValue] = useState<any>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [binaryData, setBinaryData] = useState<any>();
  const [catchAddress, setCatchAddress] = useState("");
  const [isFull, setIsFull] = useState(false)

  //on show affiliates tree
  const onShowAffiliateTree = async () => {
    setLoading(true);
    try {
      const res = await AuthService.affiliatesTree(catchAddress);
      if (res) {
        setBinaryData(res);
      } else {
        toast.error("Check your address");
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    onShowAffiliateTree();
  }, []);

  useEffect(() => {
    if (catchAddress.length > 43) {
      onShowAffiliateTree();
    }
  }, [catchAddress.length]);

  const convertBinaryDataToTree = (node: any, isLeftNode = true) => {
    if (!node || !node.node) return null;
    const newNode: any = {
      name: !node.node.fullname ? node.node.address : node.node.fullname,
      attributes: {
        fullname: !node.node.fullname ? node.node.address : node.node.fullname,
        address: node.node.address,
        email: node.node.email,
        accountID: node.node.user_id,
        created_at: node.node.created_at,
        leadership_level: node.node.leadership_level,
        left_revenue: node.node.left_branch_revenue,
        right_revenue: node.node.right_branch_revenue,
        package: node.node.package ? node.node.package : {},
      },
      leftNode: isLeftNode,
      children: [],
    };

    if (node.left && node.left.node) {
      const leftChild = convertBinaryDataToTree(node.left, true);
      if (leftChild) {
        newNode.children.push(leftChild);
      }
    }

    if (node.right && node.right.node) {
      const rightChild = convertBinaryDataToTree(node.right, false);
      if (rightChild) {
        newNode.children.push(rightChild);
      }
    }

    return newNode;
  };

  const collectTreeOptions = (node: any, collectedOptions: any[] = []) => {
    if (!node) return collectedOptions;
    if (node.name) {
      collectedOptions.push({
        value: node.name,
        text: node.name,
      });
    }
    node.children.forEach((child: any) =>
      collectTreeOptions(child, collectedOptions)
    );
    return collectedOptions;
  };

  useEffect(() => {
    if (binaryData) {
      const tree = convertBinaryDataToTree(binaryData);
      if (tree) {
        setTreeData([tree]);
        const allOptions = collectTreeOptions(tree);
        setOptions(allOptions);
      }
    }
  }, [binaryData]);

  const renderRectSvgNode = ({
    nodeDatum,
    toggleNode,
    hierarchyPointNode,
  }: any) => {
    const isLeftNodePosition = nodeDatum.leftNode === true;
    const isLeftNode = nodeDatum?.children?.length > 0 ? true : false;
    const x = isLeftNodePosition ? -35 : 20;
    return (
      <g id="wrapNode">
        <circle strokeWidth="1" fill={isLeftNode ? "#2c2c2c" : "red"} r="10" />
        <NodeItem
          target={target}
          hierarchyPointNode={hierarchyPointNode}
          nodeDatum={nodeDatum}
          toggleNode={() => {
            if (!nodeDatum._children) {
              toggleNode();
            }
          }}
          setTarget={setTarget}
          isLeftNodePosition={isLeftNodePosition}
          setTargetClicked={setTargetClicked}
          targetClicked={targetClicked}
          x={x}
          setIsModalOpen={setIsModalOpen}
          setTargetSearch={setTargetSearch}
          value={value}
        />
      </g>
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    p: 2,
    borderRadius: "20px",
  };

  const onChange = (event: any, newValue: string | null) => {
    setValue(newValue);
  };

  //const handle show full
  const handleShowFull = () => {
    setIsFull(!isFull)
  }

  return (
    <>
      <TextField
        id="outlined-basic"
        label="Search address"
        variant="outlined"
        onChange={(e: any) => {
          if (e.target.value.length > 43) {
            setCatchAddress(e.target.value);
          } else {
            toast.error("Invalid address", { id: "invalid" });
          }
        }}
      />
      <Autocomplete
        value={value}
        options={options.map((option) => option.value)}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} label="Please search..." variant="outlined" />
        )}
        getOptionLabel={(option) => option}
        clearOnEscape
      />
      <BinaryInner className={isFull ? 'is-full' : ''}>
      <ButtonShowFull>
        <Button className="button-full-screen" onClick={handleShowFull}>
          <img src={isFull ? '/assets/images/common/icn_minimize.png' : '/assets/images/common/icn_fullscreen.png'} width={32} height={32} alt="" />
        </Button>
      </ButtonShowFull>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isModalOpen}
          onClose={handleCancel}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isModalOpen}>
            <Box sx={style}>
              <NodeModal target={target} />
            </Box>
          </Fade>
        </Modal>
        <>
          {loading ? (
            <>Loading....</>
          ) : (
            <Tree
              separation={{ siblings: 7, nonSiblings: 7 }}
              translate={
                targetSearch
                  ? {
                      x: window.innerWidth / 2 - targetSearch?.x,
                      y: 100 - targetSearch?.y,
                    }
                  : { x: window.innerWidth / 2, y: 100 }
              }
              data={treeData || []}
              renderCustomNodeElement={renderRectSvgNode}
              orientation="vertical"
            />
          )}
        </>
      </BinaryInner>
    </>
  );
};
export default AffiliateTree;
