import styled from "styled-components";
export const BlockTitleFlex = styled.div`
  & > figure {
    position: absolute;
    top: 0;
    right: 0;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    & > p {
      margin: 0;
      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
    &.column {
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      & > p {
        margin: 0;
        &:nth-child(2) {
          justify-content: space-between;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;
export const BlockCardLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  width: 165px;
  min-height: 100px;
  padding: 12px;
  border-radius: 8px;
  background: #2c2c2c;
  & > figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 10px;
    margin: 0;
  }
  ${BlockTitleFlex} {
    & > figure {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
    }
  }
  &.card_level {
    width: 180px;
  }
  &.level_00 {
    background: url("/img/common/level_bg_00.jpg") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 0;
        right: 0;
        mix-blend-mode: luminosity;
      }
    }
  }
  &.level_0 {
    background: url("/img/common/level_bg_0.jpg") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 0;
        right: 0;
        mix-blend-mode: luminosity;
      }
    }
  }
  &.level_1 {
    background: url("/img/common/level_bg_1.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 0;
        right: 0;
        mix-blend-mode: luminosity;
      }
    }
  }
  &.level_2 {
    background: url("/img/common/level_bg_2.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: plus-lighter;
      }
    }
  }
  &.level_3 {
    background: url("/img/common/level_bg_3.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: screen;
      }
    }
  }
  &.level_4 {
    background: url("/img/common/level_bg_4.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: color-dodge;
      }
    }
  }
  &.level_5 {
    background: url("/img/common/level_bg_5.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: color-dodge;
      }
    }
  }
  &.level_6 {
    background: url("/img/common/level_bg_6.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: overlay;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    &.card_level {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    &.card_level {
      width: calc(100% - 24px);
    }
  }
`;
export const BlockTitle = styled.p`
  color: #757575;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    white-space: nowrap;
  }
`;
export const BlockText = styled.p`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: #fff;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    color: #757575;
    font-size: 14px;
  }
`;
export const TreeModalContainer = styled.div`
  ${BlockCardLayout} {
    width: 100%;
    margin-bottom: 15px;
  }
  & > div {
    p:last-child {
      display: flex;
      align-items: center;
      gap: 5px;
      line-height: normal;
    }
  }
  p {
    margin: 0;
  }
`;
