import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormProvider, {
  RHFRadioGroup,
  RHFTextField,
} from "src/components/hook-form";
import {
  BlockUpdateUser,
  FormSubmitButton,
  FormTitle,
  UpdateUserContainer,
} from "./styled";
import { shortenAddress } from "src/components/shortAddress";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import ENDPOINT from "src/services/Endpoint";
import { AuthService } from "src/services";
import { Button } from "@mui/material";

interface FormValuesProps {
  fullname: string;
  email: string;
  is_support: boolean;
  is_transfer: boolean;
  is_swap: boolean;
  is_lock: boolean;
  address: string;
}

const FormUpdateUser = ({ row, handleCloseUpdate, onShowUser }: any) => {
  const NewAddressSchema = Yup.object().shape({
    fullname: Yup.string(),
    email: Yup.string().email(),
    is_support: Yup.boolean(),
    is_transfer: Yup.boolean(),
    is_swap: Yup.boolean(),
    is_lock: Yup.boolean().required(),
    address: Yup.string(),
  });

  const defaultValues = {
    fullname: "",
    email: "",
    is_support: row?.is_support,
    is_transfer: row?.is_transfer,
    is_swap: row?.is_swap,
    is_lock: row?.is_lock,
    address: "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const watchAddress = watch("address");

  const [isValidAddress, setIsValidAddress] = useState(false);
  const handleCheckUserExist = async () => {
    if (watchAddress.length < 44) {
      toast.error("Invalid address");
      return;
    }
    try {
      const response = await axios.get(
        `https://adminapi.b2bwao.com${ENDPOINT.USER}/${watchAddress}/check`
      );
      if (response?.data.is_exists) {
        toast.error("This address is exists in system");
        setIsValidAddress(false);
      } else {
        toast.success("Valid address");
        setIsValidAddress(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (watchAddress.length > 42) {
      handleCheckUserExist();
    }
  }, [watchAddress.length]);

  const onSubmit = async (data: FormValuesProps) => {
    if (!isValidAddress) {
      toast.error("Check your address again");
      return;
    }
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
    );
    try {
      console.log('aaa');
    } catch (error) {
      console.error(error);
      toast.error("Some invalid input");
    }
  };

  return (
    <UpdateUserContainer>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Full Name</span>
          </FormTitle>
          <RHFTextField name="name" label="Name" defaultValue={row?.name} />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Value</span>
          </FormTitle>
          <RHFTextField name="value" label="Value" defaultValue={row?.value}/>
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormSubmitButton className="active" disabled={isSubmitting}>
            Update
          </FormSubmitButton>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleCloseUpdate}
          >
            Cancel
          </Button>
        </BlockUpdateUser>
      </FormProvider>
    </UpdateUserContainer>
  );
};

export default FormUpdateUser;
