import { ApexOptions } from "apexcharts";
// @mui
import { Box, Card, Typography, CardProps } from "@mui/material";
import { convertFixed } from "src/utils/formatNumber";
import { CardContainer, CardImage } from "./styled";

interface Props extends CardProps {
  title: string;
  total?: any;
  percent?: number;
  image: string;
  backgroundColor: string;
  color: string;
  chart: {
    colors?: string[];
    series: number[];
    options?: ApexOptions;
  };
  name?: string;
}

export default function CardCommon({
  title,
  percent,
  total,
  image,
  chart,
  backgroundColor,
  color,
  name,
  sx,
  ...other
}: Props) {
  return (
    <CardContainer>
      <Card
        sx={{ display: "flex", alignItems: "center", px: 2, py: 1, ...sx }}
        {...other}
        className="card-common"
        style={{ backgroundColor: backgroundColor, color: color }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="subtitle2" paragraph sx={{ mb: 0 }}>
                {title}
              </Typography>

              {name ? (
                <Typography variant="h4" gutterBottom sx={{ mb: 0 }}>
                  {name}
                </Typography>
              ) : (
                <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                  {convertFixed(Number(total || 0))}
                </Typography>
              )}
            </Box>
            <Box>
              <CardImage>
                <img src={image} width={30} height={30} alt="icon" />
              </CardImage>
            </Box>
          </Box>
        </Box>
      </Card>
    </CardContainer>
  );
}
