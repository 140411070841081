import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import sumBy from "lodash/sumBy";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Divider,
  TableBody,
  Container,
  TableContainer,
  Grid,
  Button,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// utils
import { fTimestamp } from "../../../utils/formatTime";
// _mock_
import { _invoices } from "../../../_mock/arrays";
// @types
import { IInvoice } from "../../../@types/invoice";
// components
import Label from "../../../components/label";
import Scrollbar from "../../../components/scrollbar";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  getComparator,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import InvoiceAnalytic from "../../../sections/@dashboard/invoice/InvoiceAnalytic";
import { InvoiceTableToolbar } from "../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";
import moment from "moment";
import WalletIcon from "@mui/icons-material/Wallet";
import { EcommerceWidgetSummary } from "src/sections/@dashboard/general/e-commerce";
import CardCommon from "src/components/cardCommon";
import FilterSelect from "src/components/filterSelect";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  "all",
  "AFFILIATES_BONUS",
  "INVESTMENT",
  "PROFIT_DAILY",
  "BRANCH_BONUS",
  "RANKING_BONUS",
  "WITHDRAWAL",
  "CLAIM",
  "RANKING_BONUS_DAILY",
  "INVESTMENT_BONUS",
  "BUY_TOKEN_OTC",
];

const TABLE_HEAD = [
  { id: "date", label: "Create Date", align: "left" },
  { id: "addressCreator", label: "Address Creator", align: "left" },
  { id: "addressUser", label: "Address User", align: "left" },
  { id: "type", label: "Type", align: "left" },
  { id: "amoutUsd", label: "Amout (USDT)", align: "left", width: 140 },
  { id: "token", label: "Token Value", align: "left" },
  { id: "description", label: "Description", align: "left", width: 140 },
];

// ----------------------------------------------------------------------

export default function GeneralDashbboard({ data }: any) {
  const { themeStretch } = useSettingsContext();
  const theme = useTheme();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>();
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<any>(null);
  const [filterStartDate, setFilterStartDate] = useState<any>(null);
  const [day, setDay] = useState(moment().format("YYYY-MM-DD"));
  const [dataRevenue, setDataRevenue] = useState<any>();
  const [dataUser, setDataUser] = useState<any>();
  const [currentDay, setCurrentDay] = useState<any>();
  const [dataStaking, setDataStaking] = useState<any>()
  const accessToken: any = localStorage.getItem("accessToken");

  const isFiltered =
    filterStatus !== "all" ||
    filterName !== "" ||
    filterService !== "all" ||
    (!!filterStartDate && !!filterEndDate);

  const getLengthByStatus = (status: string) =>
    tableData?.filter((item: any) => item?.remark === status)?.length;

  const getTotalPriceByStatus = (status: string) =>
    sumBy(
      tableData?.filter((item: any) => item.status === status),
      "totalPrice"
    );

  const getPercentByStatus = (status: string) =>
    (getLengthByStatus(status) / tableData?.length) * 100;

  const SELECTDATA = [
    { value: "", label: "All", color: "info", count: currentPage?.total },
    {
      value: "PACKAGE",
      label: "Package",
      color: "warning",
    },
    {
      value: "PROFIT_DAILY",
      label: "Profit Daily",
      color: "error",
    },
    {
      value: "BRANCH_BONUS",
      label: "Branch Bonus",
      color: "default",
    },
    {
      value: "STAKING",
      label: "Staking",
      color: "default",
    },
    {
      value: "WITHDRAW",
      label: "Withdrawal",
      color: "default",
    },
    /* { value: 'CLAIM', label: 'Claim', color: 'default' },*/
    {
      value: "DIRECT_COMMISSION",
      label: "Direct Commission",
      color: "default",
    },
    {
      value: "GROWTH_COMMISSION",
      label: "Growth Commission",
      color: "default",
    },

    {
      value: "INTEREST_ON_INTEREST",
      label: "Investment Bonus",
      color: "default",
    },
    {
      value: "BUY_TOKEN_OTC",
      label: "Buy Token OTC",
      color: "default",
    },
  ];

  const handleFilterStatus = (
    // event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("");
    setFilterEndDate("");
    setFilterStartDate("");
  };

  // api dashboard/transaction
  const onShowTransaction = async () => {
    try {
      const params = {
        page: page + 1,
        perPage: rowsPerPage,
        address: filterName,
        type: filterService || filterStatus,
        start_date: filterStartDate,
        end_date: filterEndDate,
      };
      const res: any = await AuthService.transaction(params);
      setTableData(res?.data);
      setDataRevenue(res);
      setCurrenPage(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    onShowTransaction();
  }, [
    filterName,
    filterService,
    filterEndDate,
    page,
    filterStatus,
    rowsPerPage,
  ]);

  //api dashbaord/revenue
  const onShowRevenue = async () => {
    try {
      const params = {
        startDay: day,
        endDay: day,
      };
      const res = await AuthService.revenue(params);
      setDataRevenue(res);
    } catch (error) {
      console.log(error);
    }
  };

  const onShowUser = async () => {
    try {
      const params = {
        startDay: day,
        endDay: day,
      };
      const res = await AuthService.retportUser(params);
      setDataUser(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //onShowRevenue();
    onShowUser();
  }, []);

  //get current day
  const getCurrentDay = () => {
    let currentDate = new Date();
    setCurrentDay(moment(currentDate).utc().format("DD/MM/YYYY"));
  };
  useEffect(() => {
    getCurrentDay();
  }, []);

  //export data
  const handleExportData = async (format: "xlsx" | "csv") => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("authorization", accessToken);
      const params: any = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        "https://admin99cms.wicchain.net/exports",
        params
      );

      if (!response.ok) {
        throw new Error(
          `Network response was not ok, status: ${response.status}`
        );
      }

      const fileBlob = await response.blob();
      saveAs(fileBlob, `DatasWicchain.${format}`);

      if (!Array.isArray(data)) {
        console.error("Expected an array of data objects for export.");
        return;
      }

      const dataToExport = data.map((item) => ({
        Date: item.date,
        AddressCreator: item.addressCreator,
        AddressUser: item.addressUser,
        Type: item.type,
        AmountUsd: item.amountUsd,
        Token: item.token,
        Description: item.description,
      }));

      if (format === "xlsx") {
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "DatasWicchain");

        const excelData = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "DatasWicchain.xlsx");
        console.log("Data exported successfully as .xlsx");
      } else if (format === "csv") {
        const csvData = XLSX.utils.json_to_sheet(dataToExport);
        const csvString = XLSX.utils.sheet_to_csv(csvData);
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "DatasWicchain.csv");
        console.log("Data exported successfully as .csv");
      }
    } catch (error) {
      console.error("Failed to export data", error);
    }
  };

  //get data stacking
  const getDatastakings= async() => {
    try {
      const res = await AuthService.getDataStaking()
      setDataStaking(res)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getDatastakings()
  },[])

  return (
    <>
      <Helmet>
        <title> Dashboard | Wicchain</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Dashboard"
          links={[
            {
              name: "",
            },
          ]}
        />
        <h2>Total</h2>
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Member"
                percent={2.6}
                total={dataUser?.total}
                image="/assets/images/card/icn_01.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Wallet Token"
                total={dataUser?.total_wallet_token}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#B000F0"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#E4C2FF"}
                color="#B000F0"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Profit Daily"
                total={dataUser?.total_profit_daily}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Invest"
                total={dataRevenue?.revenue?.totalPackageAmount}
                image="/assets/images/card/icn_10.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Member Actived"
                total={dataUser?.total_member_active}
                image="/assets/images/card/icn_02.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Member Support"
                total={dataUser?.total_member_support}
                image="/assets/images/card/icn_02.png"
                chart={{
                  colors: ["#B000F0"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#E4C2FF"}
                color="#B000F0"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Commission"
                total={dataUser?.total_commission}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Withdrawal"
                total={dataRevenue?.revenue?.totalWithdrawAmount}
                image="/assets/images/card/icn_10.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Staking"
                total={dataStaking?.total_staking}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Buy OTC"
                total={dataRevenue?.revenue?.totalBuyOTC}
                image="/assets/images/card/icn_16.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Pay OTC"
                total={dataRevenue?.revenue?.totalRecvBuyOTC}
                image="/assets/images/card/wicdog_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
          </Grid>
        </Container>
        <h2>Today, {currentDay}</h2>
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Member"
                percent={2.6}
                total={dataUser?.total_member_today}
                image="/assets/images/card/icn_01.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Profit Daily"
                percent={2.6}
                total={dataRevenue?.revenue?.totalProfitDailyTokenToday}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Invest"
                percent={2.6}
                total={dataRevenue?.revenue?.totalPackageAmountToDay}
                image="/assets/images/card/icn_10.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Member Actived"
                percent={2.6}
                total={dataRevenue?.revenue?.totalMemberActiveToDay}
                image="/assets/images/card/icn_02.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Commission"
                percent={2.6}
                total={dataRevenue?.revenue?.totalCommissionTokenToday}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Withdrawal"
                percent={2.6}
                total={dataRevenue?.revenue?.totalWithdrawAmountToDay}
                image="/assets/images/card/icn_10.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Staking"
                total={dataStaking?.total_staking_today}
                image="/assets/images/card/wiccoin_icon.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Buy OTC"
                total={dataRevenue?.revenue?.totalBuyOTCToday}
                image="/assets/images/card/icn_16.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Pay OTC"
                total={dataRevenue?.revenue?.totalRecvBuyOTCToday}
                image="/assets/images/card/wicdog_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
          </Grid>
        </Container>
        <h2>Transaction</h2>
        <div
          style={{
            textAlign: "right",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Button variant="outlined" onClick={() => handleExportData("xlsx")}>
            Data XLSX
          </Button>
          <Button variant="outlined" onClick={() => handleExportData("csv")}>
            Data CSV
          </Button>
        </div>
        <Card>
          <FilterSelect
            options={SELECTDATA}
            selectedValue={filterStatus}
            onChange={handleFilterStatus}
          />
          <InvoiceTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            hiddenSelect={true}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            {/* <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: any) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            /> */}

            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow key={row?.transaction_id} row={row} />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentPage?.total}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}
