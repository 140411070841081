import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../../components/settings';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../../components/table';
// sections
import { InvoiceTableToolbar } from '../../../../sections/@dashboard/invoice/list';
import { AuthService } from 'src/services';
import InvoiceTableRow from './TableRow';

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = ['all'];

const TABLE_HEAD = [
  { id: 'date', label: 'Create Date', align: 'left' },
  { id: 'address', label: 'Address', align: 'left' },
  { id: 'investToken', label: 'Invest Token', align: 'left' },
  { id: 'investUSD', label: 'Invest USD', align: 'left' },
  { id: 'profit', label: 'Profit Daily', align: 'left', width: 140 },
  { id: 'maxOut', label: 'Max Out', align: 'left', width: 140 },
  { id: 'maxOutCommission', label: 'Max Out Commission', align: 'left' },
];

// ----------------------------------------------------------------------

export default function GeneralInvesmentList({ data }: any) {
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [tableData, setTableData] = useState<any>(data);
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState('');

  const [openConfirm, setOpenConfirm] = useState(false);

  const [filterStatus, setFilterStatus] = useState('all');

  const [filterService, setFilterService] = useState('all');

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);

  const getLengthByStatus = (status: string) =>
    tableData?.filter((item: any) => item.status === status)?.length;

  const TABS = [
    { value: 'all', label: 'All', color: 'info', count: currentPage?.total },
    // { value: 'paid', label: 'No', color: 'success', count: getLengthByStatus('paid') },
    // { value: 'unpaid', label: 'No', color: 'warning', count: getLengthByStatus('unpaid') },
    // { value: 'overdue', label: 'No', color: 'error', count: getLengthByStatus('overdue') },
    // { value: 'draft', label: 'No', color: 'default', count: getLengthByStatus('draft') },
  ] as const;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('all');
    setFilterService('all');
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // show Investment list
  const onShowInvestmentList = async () => {
    try {
      const params = {
        page: page + 1,
        perPage: rowsPerPage,
        address: filterName,
        start_date: filterStartDate,
        end_date: filterEndDate
      };
      const res = await AuthService.investmentList(params);
      setTableData(res?.data);
      setCurrenPage(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onShowInvestmentList();
  }, [page, filterName, filterEndDate,rowsPerPage]);

  //handle scroll top
  const ref = useRef<any>(null);
  const handleScrollTop = ()=> {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <>
      <Helmet>
        <title> Investment List | Wicchain</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Investment List"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Investment List',
            },
          ]}
        />

        <Card ref={ref}>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={
                  <Label color={tab.color} sx={{ mr: 1 }}>
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>
          <Divider />
          <InvoiceTableToolbar
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
            hiddenSelect={true}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: any) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow key={row?.user?.address} row={row} />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentPage?.total}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
            onClick={handleScrollTop}
          />
        </Card>
      </Container>
    </>
  );
}
