import { useState } from "react";
// @mui
import { Button, TableRow, TableCell } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { IInvoice } from "src/@types/invoice";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";
import Label from "src/components/label";
import { AuthService } from "src/services";

export default function InvoiceTableRow({
  row,
  selected,
  onShowTransaction,
}: any) {
  // const { code } = row;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isCancle, setIsCancle] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleAction = (status: string) => {
    handleOpenConfirm();
    try {
      if (status === "Approve") {
        console.log("Approve");
      } else {
        setIsCancle(true);
        console.log("Cancle");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleUpdateStatusWithdraw = async () => {
    try {
      await AuthService.withdrawStatus({
        transaction_id: row?.transaction_id,
      });
      toast.success("Update successfully");
      setOpenConfirm(false);
      setTimeout(() => {
        onShowTransaction();
      }, 1500);
    } catch (error) {
      console.log(error);
      toast.error("Update error");
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment.utc(row?.created_at).format("DD/MM/YYYY") +
            " - " +
            moment.utc(row?.created_at).format("HH:mm:ss")}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.creator?.address ? (
            <CopyToClipboard
              text={row?.creator?.address}
              onCopy={() => toast.success("Coppied !")}
            >
              <div style={{ display: "flex" }}>
                <span style={{ flexShrink: "0" }}>
                  {shortenAddress(row?.creator?.address)}
                </span>
                <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
              </div>
            </CopyToClipboard>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <CopyToClipboard
            text={row?.user?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span style={{ flexShrink: "0" }}>
                {shortenAddress(row?.user?.address)}
              </span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.type_payment ? row?.type_payment : "--"}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.token_value?.toFixed(2)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
            {row?.fee ? row?.fee : "--"}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.amount ? row?.amount : "--"}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.status}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.tx_hash ? (
            <a
              style={{ color: "#000" }}
              target="_blank"
              rel="noreferrer"
              href={`https://solscan.io/tx/${row?.tx_hash}`}
            >
              {shortenAddress(row?.tx_hash)}
            </a>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.description}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.status === "SUCCESS" ? (
            <Label
              style={{ fontSize: "11px" }}
              variant="soft"
              color={"success"}
            >
              Paid
            </Label>
          ) : row?.status === "FAILED" ? (
            <Label
              onClick={() => {
                setOpenConfirm(true);
              }}
              style={{ fontSize: "10px", cursor: 'pointer' }}
              variant="soft"
              color={"error"}
            >
              {row?.status}
            </Label>
          ) : (
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Label
                style={{ fontSize: "11px", cursor: "pointer" }}
                variant="soft"
                color={"success"}
                onClick={() => handleAction("Approve")}
              >
                Approve
              </Label>{" "}
              /{" "}
              <Label
                style={{ fontSize: "11px", cursor: "pointer" }}
                variant="soft"
                color={"error"}
                onClick={() => handleAction("Cancle")}
              >
                Cancle
              </Label>
            </div>
          )}
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={isCancle ? "Cancel" : "Set to pending"}
        content={`Are you want to set it to ${isCancle ? "Cancle" : "pending"}?`}
        action={
          <Button
            onClick={() => {
              handleUpdateStatusWithdraw();
            }}
            variant="contained"
            color="success"
          >
            Yes
          </Button>
        }
      />
    </>
  );
}
