import { useEffect } from "react";
import { shortenAddress } from "src/components/shortAddress";
import {
  BorderAnimation,
  NodeImage,
  NodeInfo,
  NodeLevel,
  NodeMenu,
  NodeProfile,
  NodeWrapper,
} from "./styled";
import { isIOS } from "react-device-detect";
import PendingIcon from "@mui/icons-material/Pending";

const NodeItem = ({
  nodeDatum,
  toggleNode,
  isLeftNodePosition,
  setTarget,
  setIsModalOpen,
  setTargetSearch,
  hierarchyPointNode,
  value,
}: any) => {
  useEffect(() => {
    if (
      hierarchyPointNode?.data?.attributes?.fullname &&
      hierarchyPointNode.data.attributes.fullname === value
    ) {
      setTargetSearch(hierarchyPointNode);
    }
  }, [value, hierarchyPointNode]);

  const handleChangeName = () => {
    switch (nodeDatum?.attributes.package?.name) {
      case "Activated":
        return "level_0";
      case "Run":
        return "level_1";
      case "Venus":
        return "level_2";
      case "Jupiter":
        return "level_3";
      case "Mercury":
        return "level_4";
      case "Mars":
        return "level_5";
      case "Saturin":
        return "level_6";
      default:
        return "level_00";
    }
  };

  const handleChangeIOS = () => {
    switch (nodeDatum?.attributes.package?.name) {
      case "Activated":
        return "/img/Common/graph_chart_0.jpg";
      case "Run":
        return "/img/Common/graph_chart_01.jpg";
      case "Venus":
        return "/img/Common/graph_chart_02.jpg";
      case "Jupiter":
        return "/img/Common/graph_chart_03.jpg";
      case "Mercury":
        return "/img/Common/graph_chart_04.jpg";
      case "Mars":
        return "/img/Common/graph_chart_05.jpg";
      case "Saturin":
        return "/img/Common/graph_chart_06.jpg";
      default:
        return "/img/Common/graph_chart_00.jpg";
    }
  };

  // For IOS
  const foreignObjectProps = {
    width: "350",
    height: "120",
    x: isLeftNodePosition ? -390 : 40,
    y: -50,
  };

  const handleChangeColor = () => {
    switch (nodeDatum?.attributes.package?.name) {
      case "Activated":
        return "#fff";
      case "Run":
        return "#81c8ff";
      case "Venus":
        return "#fff";
      case "Jupiter":
        return "#d6a7ff";
      case "Mercury":
        return "#ffc9c4";
      case "Mars":
        return "#ffe89d";
      case "Saturin":
        return "#ffd0c0";
      default:
        return "#fff";
    }
  };

  return (
    <>
      <foreignObject
        x={isLeftNodePosition ? "-390" : "40"}
        y={isLeftNodePosition ? "-50" : "-50"}
        width="350"
        height="120"
      >
        <NodeWrapper
          className={handleChangeName()}
          onClick={(e: any) => {
            toggleNode();
            e.stopPropagation();
          }}
        >
          <BorderAnimation
            style={{
              visibility:
                hierarchyPointNode.data.attributes?.fullname === value
                  ? "visible"
                  : "hidden",
            }}
          />
          <NodeMenu
            onClick={(e: any) => {
              setTarget(nodeDatum);
              setIsModalOpen(true);
              e.stopPropagation();
            }}
          >
            <PendingIcon />
          </NodeMenu>
          <NodeInfo>
            <NodeImage>
              <img
                style={{
                  mixBlendMode: "luminosity",
                }}
                src="/img/common/logo_small.png"
                alt="avatar"
              />
            </NodeImage>
            <NodeProfile>
              <p>
                Name:{" "}
                {nodeDatum?.attributes.fullname ===
                nodeDatum?.attributes.address
                  ? shortenAddress(nodeDatum?.attributes.fullname)
                  : nodeDatum?.attributes.fullname}
              </p>
              <p>Address: {shortenAddress(nodeDatum?.attributes.address)}</p>
              <p>
                Total Invest:
                <span>
                  {" "}
                  $
                  {!nodeDatum?.attributes?.package
                    ? "0"
                    : nodeDatum?.attributes?.package?.usdt_amount}
                </span>
              </p>
            </NodeProfile>
          </NodeInfo>
          <NodeLevel>
            <p>
              {nodeDatum?.attributes?.package
                ? nodeDatum?.attributes?.package?.name
                : ""}
            </p>
          </NodeLevel>
        </NodeWrapper>
      </foreignObject>
    </>
  );
};

export default NodeItem;
