import { useState } from "react";
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import MenuPopover from "src/components/menu-popover/MenuPopover";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { IInvoice } from "src/@types/invoice";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";

// ----------------------------------------------------------------------

// Define a union type for the possible values of row.type
type InvoiceType =
  | 'PACKAGE'
  | 'PROFIT_DAILY'
  | 'BRANCH_BONUS'
  | 'WITHDRAW'
  | 'DIRECT_COMMISSION'
  | 'GROWTH_COMMISSION'
  | 'INTEREST_ON_INTEREST'
  | 'BUY_TOKEN_OTC';

const typeLabels: Record<InvoiceType, string> = {
  PACKAGE: 'Buy Package',
  PROFIT_DAILY: 'Profit Daily',
  BRANCH_BONUS: 'Branch Bonus',
  WITHDRAW: 'Withdraw',
  DIRECT_COMMISSION: 'Direct Commission',
  GROWTH_COMMISSION: 'Growth Commission',
  INTEREST_ON_INTEREST: 'Reward on Commission',
  BUY_TOKEN_OTC: 'Buy WIC OTC',
};

type Props = {
  row: IInvoice;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function InvoiceTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: any) {
  // const { code } = row;
  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment.utc(row?.created_at).format("DD/MM/YYYY") +
            " - " +
            moment.utc(row?.created_at).format("HH:mm:ss")}
        </TableCell>

        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.creator?.address ? (
            <CopyToClipboard
              text={row?.creator?.address}
              onCopy={() => toast.success("Coppied !")}
            >
              <div style={{ display: "flex" }}>
                <span style={{ flexShrink: "0" }}>
                  {shortenAddress(row?.creator?.address)}
                </span>
                <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
              </div>
            </CopyToClipboard>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <CopyToClipboard
            text={row?.user?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span style={{ flexShrink: "0" }}>
                {shortenAddress(row?.user?.address)}
              </span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {typeLabels[row?.type as InvoiceType] || ''}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.amount?.toFixed(2)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.token_value}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.description ? row?.description : "--"}
        </TableCell>
      </TableRow>
    </>
  );
}
