import { TextField, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';

type TabOption = {
  value: string;
  label: string;
  color: string;
  count?: number;
};

type Props = {
  options: TabOption[];
  selectedValue: string;
  onChange: (value: string) => void;
};

export default function FilterSelect({ options, selectedValue, onChange }: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2, py: 2 }}
      style={{paddingBottom: '0'}}
    >
      <TextField
        select
        label="Filter"
        value={selectedValue}
        onChange={(event) => onChange(event.target.value)}
        fullWidth
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: 260,
              },
            },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}
