import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
} from './elements';
import { PATH_DASHBOARD } from './paths';
import GeneralInvesment from 'src/pages/dashboard/GeneralInvesment';
import GeneralLevel from 'src/pages/dashboard/GeneralLevel';
import GeneralAffiliatesUp from 'src/pages/dashboard/GeneralAffiliatesUp';
import GeneralAffiliatesDown from 'src/pages/dashboard/GeneralAffiliatesDown';
import GeneralInvesmentList from 'src/pages/dashboard/Investments/InvestmentList/GeneralInvestmentList2';
import GeneralDashbboard from 'src/pages/dashboard/Dashboard/GeneralDashboard';
import GeneralLogActionPage from 'src/pages/dashboard/Logs/LogAction';
import GeneralLogBalancePage from 'src/pages/dashboard/Logs/LogBalance';
//import GeneralWithdraw from 'src/pages/dashboard/Withdraw/GeneralInvestmentList2';
import GeneralWithdraw from 'src/pages/dashboard/Withdraw';
import GeneralSetting from 'src/pages/dashboard/Setting/GeneralSetting';
import GeneralUser from 'src/pages/dashboard/User/GeneralUser';
import GeneralInvesmentPackage from 'src/pages/dashboard/Investments/InvestmentPackage/GeneralInvestmentPackage';
import GeneralAffiliatesLeft from 'src/pages/dashboard/AffiliatesList/Left';
import AffiliateTree from 'src/pages/dashboard/AffilliatesTree';
import GeneralTransaction from 'src/pages/dashboard/Transaction';
import InvesmentAdd from 'src/pages/dashboard/Investments/InvestmentAdd';
import ReportUser from 'src/pages/dashboard/ReportUser';
import Package from 'src/pages/dashboard/Package';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralDashbboard /> },
        { path: 'manage-user', element: <GeneralUser /> },
        { path: 'withdrawal', element: <GeneralWithdraw /> },
        { path: 'setting', element: <GeneralSetting /> },
        { path: 'round', element: <GeneralBookingPage /> },
        { path: 'level', element: <GeneralLevel /> },
        { path: 'transaction', element: <GeneralTransaction /> },
        { path: 'report-user', element: <ReportUser /> },
        { path: 'package', element: <Package /> },
        {
          path: 'investment',
          children: [
            { path: 'package', element: <GeneralInvesmentPackage /> },
            { path: 'list', element: <GeneralInvesmentList /> },
            { path: 'add', element: <InvesmentAdd /> },
            // { path: 'list2', element: <GeneralInvesmentList2 /> },
          ]
        },
        {
          path: 'affiliates',
          children: [
            { path: 'list', element: <GeneralAffiliatesLeft /> },
            { path: 'tree', element: <AffiliateTree /> },
          ]
        },
        {
          path: 'Log',
          children: [
            { path: 'action', element: <GeneralLogActionPage /> },
            { path: 'balance', element: <GeneralLogBalancePage /> },
          ]
        },
        // {
        //   path: 'user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
        //     { path: 'profile', element: <UserProfilePage /> },
        //     { path: 'cards', element: <UserCardsPage /> },
        //     { path: 'list', element: <UserListPage /> },
        //     { path: 'new', element: <UserCreatePage /> },
        //     { path: ':name/edit', element: <UserEditPage /> },
        //     { path: 'account', element: <UserAccountPage /> },
        //   ],
        // },
      //   {
      //     path: 'invoice',
      //     children: [
      //       { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
      //       { path: 'list', element: <InvoiceListPage /> },
      //       { path: ':id', element: <InvoiceDetailsPage /> },
      //       { path: ':id/edit', element: <InvoiceEditPage /> },
      //       { path: 'new', element: <InvoiceCreatePage /> },
      //     ],
      //   },
      //   {
      //     path: 'blog',
      //     children: [
      //       { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
      //       { path: 'posts', element: <BlogPostsPage /> },
      //       { path: 'post/:title', element: <BlogPostPage /> },
      //       { path: 'new', element: <BlogNewPostPage /> },
      //     ],
      //   },
      //   { path: 'files-manager', element: <FileManagerPage /> },
      //   {
      //     path: 'mail',
      //     children: [
      //       { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
      //       { path: 'label/:customLabel', element: <MailPage /> },
      //       { path: 'label/:customLabel/:mailId', element: <MailPage /> },
      //       { path: ':systemLabel', element: <MailPage /> },
      //       { path: ':systemLabel/:mailId', element: <MailPage /> },
      //     ],
      //   },
      //   {
      //     path: 'chat',
      //     children: [
      //       { element: <ChatPage />, index: true },
      //       { path: 'new', element: <ChatPage /> },
      //       { path: ':conversationKey', element: <ChatPage /> },
      //     ],
      //   },
      //   { path: 'calendar', element: <CalendarPage /> },
      //   { path: 'kanban', element: <KanbanPage /> },
      //   { path: 'permission-denied', element: <PermissionDeniedPage /> },
      //   { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    // {
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <AboutPage /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <FaqsPage /> },
    //     // Demo Components
    //     {
    //       path: 'components',
    //       children: [
    //         { element: <ComponentsOverviewPage />, index: true },
    //         {
    //           path: 'foundation',
    //           children: [
    //             { element: <Navigate to="/components/foundation/colors" replace />, index: true },
    //             { path: 'colors', element: <FoundationColorsPage /> },
    //             { path: 'typography', element: <FoundationTypographyPage /> },
    //             { path: 'shadows', element: <FoundationShadowsPage /> },
    //             { path: 'grid', element: <FoundationGridPage /> },
    //             { path: 'icons', element: <FoundationIconsPage /> },
    //           ],
    //         },
    //         {
    //           path: 'mui',
    //           children: [
    //             { element: <Navigate to="/components/mui/accordion" replace />, index: true },
    //             { path: 'accordion', element: <MUIAccordionPage /> },
    //             { path: 'alert', element: <MUIAlertPage /> },
    //             { path: 'autocomplete', element: <MUIAutocompletePage /> },
    //             { path: 'avatar', element: <MUIAvatarPage /> },
    //             { path: 'badge', element: <MUIBadgePage /> },
    //             { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
    //             { path: 'buttons', element: <MUIButtonsPage /> },
    //             { path: 'checkbox', element: <MUICheckboxPage /> },
    //             { path: 'chip', element: <MUIChipPage /> },
    //             { path: 'data-grid', element: <MUIDataGridPage /> },
    //             { path: 'dialog', element: <MUIDialogPage /> },
    //             { path: 'list', element: <MUIListPage /> },
    //             { path: 'menu', element: <MUIMenuPage /> },
    //             { path: 'pagination', element: <MUIPaginationPage /> },
    //             { path: 'pickers', element: <MUIPickersPage /> },
    //             { path: 'popover', element: <MUIPopoverPage /> },
    //             { path: 'progress', element: <MUIProgressPage /> },
    //             { path: 'radio-button', element: <MUIRadioButtonsPage /> },
    //             { path: 'rating', element: <MUIRatingPage /> },
    //             { path: 'slider', element: <MUISliderPage /> },
    //             { path: 'stepper', element: <MUIStepperPage /> },
    //             { path: 'switch', element: <MUISwitchPage /> },
    //             { path: 'table', element: <MUITablePage /> },
    //             { path: 'tabs', element: <MUITabsPage /> },
    //             { path: 'textfield', element: <MUITextFieldPage /> },
    //             { path: 'timeline', element: <MUITimelinePage /> },
    //             { path: 'tooltip', element: <MUITooltipPage /> },
    //             { path: 'transfer-list', element: <MUITransferListPage /> },
    //             { path: 'tree-view', element: <MUITreesViewPage /> },
    //           ],
    //         },
    //         {
    //           path: 'extra',
    //           children: [
    //             { element: <Navigate to="/components/extra/animate" replace />, index: true },
    //             { path: 'animate', element: <DemoAnimatePage /> },
    //             { path: 'carousel', element: <DemoCarouselsPage /> },
    //             { path: 'chart', element: <DemoChartsPage /> },
    //             { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
    //             { path: 'editor', element: <DemoEditorPage /> },
    //             { path: 'form-validation', element: <DemoFormValidationPage /> },
    //             { path: 'image', element: <DemoImagePage /> },
    //             { path: 'label', element: <DemoLabelPage /> },
    //             { path: 'lightbox', element: <DemoLightboxPage /> },
    //             { path: 'map', element: <DemoMapPage /> },
    //             { path: 'mega-menu', element: <DemoMegaMenuPage /> },
    //             { path: 'multi-language', element: <DemoMultiLanguagePage /> },
    //             { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
    //             { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
    //             { path: 'scroll', element: <DemoScrollbarPage /> },
    //             { path: 'snackbar', element: <DemoSnackbarPage /> },
    //             { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
    //             { path: 'upload', element: <DemoUploadPage /> },
    //             { path: 'markdown', element: <DemoMarkdownPage /> },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { path: 'pricing', element: <PricingPage /> },
    //     { path: 'payment', element: <PaymentPage /> },
    //   ],
    // },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to={PATH_DASHBOARD.general.app} replace /> },
  ]);
}
