import CardLevel from "src/components/CardLevel";
import { TreeModalContainer } from "./styled";
import { convertFixed } from "src/utils/formatNumber copy";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const NodeModal = ({ target }: any) => {
  return (
    <TreeModalContainer>
      <CardLevel target={target} />
      <div>
        <p>Account ID</p>
        <p>
          <KeyboardDoubleArrowRightIcon />
          {target.attributes.accountID}{" "}
        </p>
      </div>
      <div>
        <p>Ranking</p>
        <p>
          <KeyboardDoubleArrowRightIcon />
          {target.attributes.leadership_level}{" "}
        </p>
      </div>
      <div>
        <p>Left branch revenue</p>
        <p>
          <KeyboardDoubleArrowRightIcon />
          {convertFixed(target.attributes.left_revenue)}{" "}
        </p>
      </div>
      <div>
        <p>Right branch revenue</p>
        <p>
          <KeyboardDoubleArrowRightIcon />
          {convertFixed(target.attributes.right_revenue)}{" "}
        </p>
      </div>
    </TreeModalContainer>
  );
};

export default NodeModal;
