import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import sumBy from 'lodash/sumBy';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import { fTimestamp } from '../../../utils/formatTime';
// _mock_
import { _invoices } from '../../../_mock/arrays';
// @types
import { IInvoice } from '../../../@types/invoice';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../components/table';
// sections
import InvoiceAnalytic from '../../../sections/@dashboard/invoice/InvoiceAnalytic';
import { InvoiceTableToolbar } from '../../../sections/@dashboard/invoice/list';
import { AuthService } from 'src/services';
import InvoiceTableRow from './TableRow';
import moment from 'moment';
import ModalSetting from '../ModalSetting';
import ModalCreate from '../ModalSetting/ModalCreate';

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD = [
  { id: 'date', label: 'Create Date', align: 'left' },
  { id: 'settingId', label: 'Setting Id', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'value', label: 'Value', align: 'left' },
  { id: "edit", label: "Edit", align: "left" },
  { id: "delete", label: "Delete", align: "left" },
];

// ----------------------------------------------------------------------

export default function GeneralSetting({ data }: any) {
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [tableData, setTableData] = useState<any>(data);
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState('');

  const [filterStatus, setFilterStatus] = useState('all');

  const [filterService, setFilterService] = useState('all');

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const getLengthByStatus = (status: string) =>
    tableData?.filter((item: any) => item.status === status)?.length;
  const TABS = [
    { value: 'all', label: 'All', color: 'info', count: currentPage?.total },
    // { value: 'paid', label: 'Paid', color: 'success', count: getLengthByStatus('paid') },
    // { value: 'unpaid', label: 'Unpaid', color: 'warning', count: getLengthByStatus('unpaid') },
    // { value: 'overdue', label: 'Overdue', color: 'error', count: getLengthByStatus('overdue') },
    // { value: 'draft', label: 'Draft', color: 'default', count: getLengthByStatus('draft') },
  ] as const;

  const handleFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('all');
    setFilterService('all');
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // show Investment list
  const onShowInvestmentPackage = async () => {
    try {
      const params = {
        page: page + 1,
        perPage: 100,
      };
      const res = await AuthService.setting(params);
      setTableData(res?.data);
      setCurrenPage(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onShowInvestmentPackage();
  }, [page]);

  //modal
  const handleOpen = (data: any) => {
    setOpen(true);
    setModalData(data);
  };
  const handleClose = () => setOpen(false);
  const handleCloseModalCreate = () => setOpenModalCreate(false);

  //handle create
  const handleCreateSetting = () => {
    setOpenModalCreate(true);
  };

  return (
    <>
      <Helmet>
        <title> Setting | Wicchain</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Setting"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Setting',
            },
          ]}
          /*
          action={
            <Button
              onClick={() => handleCreateSetting()}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Setting
            </Button>
          }
            */
        />

        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={
                  <Label color={tab.color} sx={{ mr: 1 }}>
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>
          <Divider />
          {/* <InvoiceTableToolbar
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
          /> */}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: any) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary">
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow
                        key={row?.user?.address}
                        row={row}
                        setOpen={handleOpen}
                        open={open}
                        setModalData={setModalData}
                      />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          {/* <TablePaginationCustom
            count={currentPage?.total}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          /> */}
        </Card>
      </Container>
      <ModalSetting open={open} handleClose={handleClose} row={modalData} setOpen={setOpen} />
      <ModalCreate
        open={openModalCreate}
        handleClose={handleClose}
        setOpenModalCreate={setOpenModalCreate}
        handleCloseModalCreate={handleCloseModalCreate}
      />
    </>
  );
}
