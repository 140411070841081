const ENDPOINT = {
  // LOGIN:,
  LOGIN: '/auth/login',

  // revenue: '/api/account/login',
  REVENUE: '/dashboard/revenue',

  // transaction
  TRANSACTION: "/transactions",

  // user manage
  USER: "/users",

  // withdrawal
  WITHDRAWAL: "/wallets/bo/withdrawal",

  // setting
  SETTING: "/settings",

  // round
  ROUND: "/blocks",

  // withdrawal Approve
  WITHDRAWALAPPROVE: "/wallets/bo/withdrawal",
  WITHDRAWALREJECT: "/wallets/bo/withdrawal",

  // investment package
  INVESTMENT: '/packages',

  // investment list
  INVESTMENTLIST: '/packages',

  // level
  LEVELS: '/levels',

  // affiliates
  AFFILIATES: '/networks',

  // log
  LOG: '/logs',

  // Export Data
  EXPORTDATA: '/exports'
};

export default ENDPOINT;

