import styled from "styled-components";
export const NodeProfile = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #fff;
    margin-bottom: 0;
    margin-top: 0;
    &:not(:last-child) {
      margin-bottom: 2px;
      margin-top: 0;
    }
  }
`;
export const NodeInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const NodeImage = styled.div`
  width: max-content;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(248, 248, 248, 0.05);
  background-blend-mode: luminosity;
  box-shadow: 0px 4.571px 27.429px 0px rgba(0, 0, 0, 0.3);
  img {
    width: 22px;
    height: 18px;
  }
`;
export const NodeMenu = styled.div`
  position: absolute;
  right: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  padding: 5px;
  svg {
    path {
      fill: #fff;
    }
  }
`;
export const NodeLevel = styled.div`
  position: absolute;
  right: 30px;
  bottom: 44px;
  & > p {
    position: absolute;
    left: 50%;
    transform: translateX(-60%);
    bottom: -25px;
    font-size: 15px;
    font-style: italic;
    font-weight: 600;
    margin: 0;
  }
`;
export const NodeWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  padding: 12px;
  background: url("/img/Common/graph_chart.jpg") no-repeat center right / cover;
  border: 1px solid #484848;
  &.level_00 {
    background-image: url("/img/Common/graph_chart_0.jpg");
    ${NodeLevel} {
      & > p {
        color: #fff;
      }
    }
  }
  &.level_0 {
    background-image: url("/img/Common/graph_chart_00.jpg");
    ${NodeLevel} {
      & > p {
        color: #fff;
      }
    }
  }
  &.level_1 {
    background-image: url("/img/Common/graph_chart_01.jpg");
    ${NodeLevel} {
      & > p {
        color: #81c8ff;
      }
    }
  }
  &.level_2 {
    background-image: url("/img/Common/graph_chart_02.jpg");
    ${NodeProfile} {
      p {
        color: #004352;
      }
    }
    ${NodeLevel} {
      & > p {
        color: #fff;
      }
    }
  }
  &.level_3 {
    background-image: url("/img/Common/graph_chart_03.jpg");
    ${NodeLevel} {
      & > p {
        color: #d6a7ff;
      }
    }
  }
  &.level_4 {
    background-image: url("/img/Common/graph_chart_04.jpg");
    ${NodeLevel} {
      & > p {
        color: #ffc9c4;
      }
    }
  }
  &.level_5 {
    background-image: url("/img/Common/graph_chart_05.jpg");
    ${NodeProfile} {
      p {
        color: #000;
      }
    }
    ${NodeLevel} {
      & > p {
        color: #ffe89d;
      }
    }
  }
  &.level_6 {
    background-image: url("/img/Common/graph_chart_06.jpg");
    ${NodeLevel} {
      & > p {
        color: #ffd0c0;
      }
    }
  }
`;
export const BorderAnimation = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 8px;
  filter: drop-shadow(0 0 10px #fff);
  opacity: 0.5;
  animation: RotateLine 5s linear infinite, borderOpacity 3s linear infinite;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 3px;
    background: conic-gradient(
      from calc(var(--angle) + var(--start-angle)),
      transparent 0,
      #e4c113 100%,
      transparent 25%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: RotateLine 5s linear infinite, borderOpacity 3s linear infinite;
  }
  @keyframes RotateLine {
    to {
      --angle: 360deg;
    }
  }
  @keyframes borderOpacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;
