import { useState } from "react";
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import MenuPopover from "src/components/menu-popover/MenuPopover";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { IInvoice } from "src/@types/invoice";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../../assets/image-common/icn_copy.svg";
import { formatNumberMega } from "src/utils/formatNumber";

// ----------------------------------------------------------------------

type Props = {
  row: IInvoice;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function InvoiceTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: any) {
  // const { code } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment.utc(row?.created_at).format("DD/MM/YYYY") +
            " - " +
            moment.utc(row?.created_at).format("HH:mm:ss")}
        </TableCell>

        <TableCell
          align="left"
          style={{
            display: "flex",
            borderBottom: "1px dashed rgba(241, 243, 244, 1)",
          }}
        >
          <CopyToClipboard
            text={row?.user?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span>{shortenAddress(row?.user?.address)}</span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {formatNumberMega(row?.invest_coin)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {formatNumberMega(row?.invest_usd)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.tx_hash ? <a style={{color: '#000'}} target="_blank" href={`https://solscan.io/tx/${row?.tx_hash}`}>{shortenAddress(row?.tx_hash)}</a> : '--'}
        </TableCell>
        <TableCell align="left" style={{borderBottom: '1px dashed rgba(241, 243, 244, 1)'}}>
          {row?.typePayment ? row?.typePayment : '--'}
        </TableCell>
        <TableCell align="left" style={{borderBottom: '1px dashed rgba(241, 243, 244, 1)'}}>
          {row?.isSupport ? row?.isSupport : '--'}
        </TableCell>
      </TableRow>
    </>
  );
}
