import BlockCard from "../BlockCard/index";
import { shortenAddress } from "../shortAddress";
import {
  BlockText,
  BlockTitle,
  BlockTitleFlex,
} from "src/pages/dashboard/AffilliatesTree/Node/Modal/styled";

const CardLevel = ({ target }: any) => {
  const handleChangeName = () => {
    switch (target?.attributes?.package?.name) {
      case "Activated":
        return "level_0";
      case "Run":
        return "level_1";
      case "Venus":
        return "level_2";
      case "Jupiter":
        return "level_3";
      case "Mercury":
        return "level_4";
      case "Mars":
        return "level_5";
      case "Saturin":
        return "level_6";
      default:
        return "level_00";
    }
  };

  const handleChangeFlag = () => {
    switch (target?.attributes?.package?.name) {
      case "Activated":
        return "/img/common/level_honor_1.png";
      case "Run":
        return "/img/common/level_honor_1.png";
      case "Venus":
        return "/img/common/level_honor_2.png";
      case "Jupiter":
        return "/img/common/level_honor_3.png";
      case "Mercury":
        return "/img/common/level_honor_4.png";
      case "Mars":
        return "/img/common/level_honor_5.png";
      case "Saturin":
        return "/img/common/level_honor_6.png";
      default:
        return "";
    }
  };

  const handleChangeImageLevel = () => {
    switch (target?.attributes?.package?.name) {
      case "Activated":
        return (
          <figure>
            <img
              width={117}
              height={80}
              src="/img/common/level_honor_logo_1.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        );
      case "Run":
        return (
          <figure>
            <img
              width={117}
              height={80}
              src="/img/common/level_honor_logo_1.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        );
      case "Venus":
        return (
          <figure>
            <img
              width={117}
              height={80}
              src="/img/common/level_honor_logo_2.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        );
      case "Jupiter":
        return (
          <figure>
            <img
              width={117}
              height={80}
              src="/img/common/level_honor_logo_3.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        );
      case "Mercury":
        return (
          <figure>
            <img
              width={117}
              height={80}
              src="/img/common/level_honor_logo_4.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        );
      case "Mars":
        return (
          <figure>
            <img
              width={117}
              height={80}
              src="/img/common/level_honor_logo_5.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        );
      case "Saturin":
        return (
          <figure>
            <img
              width={117}
              height={80}
              src="/img/common/level_honor_logo_6.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        );
    }
  };

  return (
    <BlockCard
      className={`card_level ${handleChangeName()}`}
      icon={handleChangeFlag()}
      imgWidth={40}
      imgHeight={57}
      component={
        <BlockTitleFlex>
          {handleChangeImageLevel()}
          <div>
            <BlockTitle
              style={{
                color: "#000",
              }}
            >
              Name
            </BlockTitle>
            <BlockText>
              <>
                {target?.attributes?.fullname === target?.attributes?.address
                  ? shortenAddress(target?.attributes?.fullname)
                  : target?.attributes?.fullname}
              </>
            </BlockText>
          </div>
          <div>
            <BlockTitle
              style={{
                color: "#000",
              }}
            >
              Package
            </BlockTitle>
            <BlockText>
              <>{target?.attributes?.package?.name || "Not yet"}</>
            </BlockText>
          </div>
          <div>
            <BlockTitle
              style={{
                color: "#000",
              }}
            >
              Club
            </BlockTitle>
            <BlockText>
              <>{target?.attributes?.leadership_level || "Not yet"}</>
            </BlockText>
          </div>
        </BlockTitleFlex>
      }
    />
  );
};

export default CardLevel;
